"use strict";
Object.defineProperty(exports, "__esModule", {
    value: true
});
exports.default = _classApplyDescriptorGet;
function _classApplyDescriptorGet(receiver, descriptor) {
    if (descriptor.get) {
        return descriptor.get.call(receiver);
    }
    return descriptor.value;
}
