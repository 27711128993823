[
  {
    "name": "Downtown",
    "hash": "downtown",
    "calendar": {
      "id": "8nfi6gevb6nirhss4apjvq5tc8@group.calendar.google.com",
    },
    "location": {
      "name": "Next Level Brewing",
      "website": "https://www.nextlevelknox.com/wordpress/",
      "map": "https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d12915.214880394811!2d-83.9235346!3d35.9762239!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x885c17faa62c0c27%3A0xecf2b101261b5df7!2sNext%20Level%20Brewing%20Company!5e0!3m2!1sen!2sus!4v1689081951766!5m2!1sen!2sus"
    }
  },
  {
    "name": "West",
    "hash": "west",
    "calendar": {
      "id": "54n8vb69dmlcjii5gef21m2lv0@group.calendar.google.com",
    },
    "location": {
      "name": "Crafty Bastard Brewary West",
      "website": "https://www.craftybastardbrewery.com/crafty-west-homepage",
      "map": "https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3231.7233057141502!2d-84.1146315!3d35.904791800000005!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x885c2f6fd8fd12e5%3A0x8a03cea5a4b2dfd4!2sCrafty%20Bastard%20Brewery%20West!5e0!3m2!1sen!2sus!4v1668540023894!5m2!1sen!2sus"
    }
  }
]
